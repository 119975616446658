import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
import Footer from "../components/footer";
import Metatags from "../components/metatags";
import Application from "../components/ecommerce/application";

require('typeface-league-spartan');

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Application mdxType="Application" />
    <Metatags title="Ecommerce site | May 28 | CSS Grid a day" description="An example of using CSS grid every day, from May 1–31st, 2019." thumbnail="https://cssgrid31.brett.cool/opengraph/may-28.png" url="https://cssgrid31.brett.cool/" pathname="/may-28" mdxType="Metatags" />
    <Footer date={28} prev={true} next={true} mdxType="Footer">
      <p>{`The Ecommerce store I’d run — Caffiend.`}</p>
      <p>{`One of the most difficult parts of this exercise was thinking up some coffee names. Content is always the hardest, innit?`}</p>
      <p><a parentName="p" {...{
          "href": "https://unsplash.com/photos/gM-RfQsZK98"
        }}>{`“Espresso Roast” Photo by Blake Richard Verdoorn on Unsplash`}</a></p>
    </Footer>

    </MDXLayout>;
}
MDXContent.isMDXComponent = true;
      